import Vue from "vue";
import VueRouter from "vue-router";
import LandingView from "../views/LandingView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: LandingView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PortfolioView.vue"),
  },
  {
    path: "/flash",
    name: "flash",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FlashView.vue"),
  },
  {
    path: "/merch",
    name: "merch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MerchView.vue"),
  },
  {
    path: "/art",
    name: "art",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArtView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
