<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="ma-5" cols="12">
        <v-row justify="center">
          <v-btn
            class="mx-2 mb-1 text-capitalize"
            rounded
            x-large
            outlined
            color="accent"
            to="/art"
          >
            Art
          </v-btn>
          <v-btn
            class="mx-2 mb-1 text-capitalize"
            rounded
            x-large
            outlined
            color="accent"
            to="/merch"
          >
            Merch
          </v-btn>
          <v-btn
            class="mx-2 text-capitalize large"
            rounded
            x-large
            outlined
            color="accent"
            href="https://crayondelune.youcanbook.me"
            target="_blank"
          >
            Reservation
            <v-icon right>mdi-open-in-new</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-row justify="center" class="mt-3">
        <v-btn
          icon
          large
          color="accent"
          href="https://www.facebook.com/profile.php?id=100076029096778"
          target="_blank"
        >
          <v-icon large color="accent"> mdi-facebook </v-icon>
        </v-btn>
        <v-btn
          icon
          large
          color="accent"
          href="https://www.instagram.com/crayondelune?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
        >
          <v-icon large color="accent">mdi-instagram</v-icon>
        </v-btn>
      </v-row>
      <CarolineBio />

      <v-col class="mb-4">
        <h2
          class="font-weight-thin font-italic mb-4"
          style="font-family: 'Montserrat', sans-serif"
        >
          "Super expérience agréable, safe space. Caroline sait comment mettre à
          l'aise sa cliente et la session se passe super bien. Elle est douce,
          rassurante et a l'écoute. Profitez-en, ce n'est tous les tattoo
          artists qui ont cette capacité 🙂" -Naenia
        </h2>
        <h2
          class="font-weight-thin font-italic mb-2"
          style="font-family: 'Montserrat', sans-serif"
        >
          "J'ai vraiment apprécié me faire tatouer par une femme talentueuse,
          ouverte, gentille et passionnée par ce qu'elle fait. C'est certain que
          j'y retourne!"- Julie
        </h2>
        <h1 class="display-2" style="color: black">Recontrez Crayondelune</h1>
        <h2 class="font-weight-light" style="color: black">
          Artiste Visionnaire
        </h2>
        <h2 class="font-weight-light" style="color: black">
          Multipotentialiste.
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CarolineBio from "@/components/CarolineBio.vue";
export default {
  name: "LandingMain",
  components: {
    CarolineBio,
  },
  data: () => ({
    tatooList: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-3.webp?alt=media&token=4bd447f5-bdcf-468c-a9c2-5594a5306a89",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-2.webp?alt=media&token=78109f6c-6b04-4fcc-a4ec-a73ece1c3ec1",
      },

      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-4.webp?alt=media&token=7eddc831-5dee-4a60-bcaf-8b1acfbf33d9",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-5.jpg?alt=media&token=b485701c-9285-4c2d-ab85-43f7aefc2850",
      },

      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-6.webp?alt=media&token=02fd061e-3bd7-42df-b1b8-a987c5325ea5",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-7.webp?alt=media&token=235e1c76-a6f3-451c-9e56-eb8f17b7c1d8",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-8.webp?alt=media&token=aeca83dc-938b-4e56-b272-ed7114a0d197",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-9.webp?alt=media&token=fa5940ea-21e2-4c36-b5b8-de9e34f4a155",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-10.webp?alt=media&token=15a2f2b5-f25a-404a-9207-c70fec61e90e",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/tatoo-11.webp?alt=media&token=3f373050-a7e0-4d58-a31e-649c806c537b",
      },
    ],
  }),
};
</script>
