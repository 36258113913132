<template>
  <v-container>
    <v-col class="mt-5" cols="12">
      <v-card class="mx-auto" width="100%" height="100%" rounded outlined>
        <PhotoCard
          source="https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/moi%20qui%20tattoo.webp?alt=media&token=644f2bf4-32de-41d7-b460-5f4659eb9a8c"
          edges="rounded-xl"
        />
      </v-card>
    </v-col>
  </v-container>
</template>
<script>
import PhotoCard from "../utils/PhotoCard.vue";
export default {
  name: "CarolineBio",
  components: {
    PhotoCard,
  },
};
</script>
