import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      // color theme config for vuetify can be entered here
      light: {
        primary: "#3d3329",
        secondary: "#F1F1F1",
        accent: "#000000",
        error: "#570f1a",
        info: "#241528",
      },
    },
    icons: {
      iconfont: "md" || "mdi", // 'mdiSvg' || 'mdi' || 'fa' || 'fa4' || 'faSvg'
    },
  },
});
