<template>
  <div class="home">
    <LandingMain />
  </div>
</template>

<script>
// @ is an alias to /src
import LandingMain from "@/components/LandingMain.vue";

export default {
  name: "LandingView",
  components: {
    LandingMain,
  },
};
</script>
