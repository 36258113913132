<template>
  <v-app style="background-color: #ffffff">
    <v-app-bar
      app
      color="secondary"
      style="font-family: Berkshire Swash, cursive"
      dark
    >
      <v-app-bar-nav-icon
        style="color: black; cursor: pointer"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/hibiscusmtl-e6e2d.appspot.com/o/feb-2024%2Flogo%2Fcryondelune_new_logo_2024.webp?alt=media&token=177bf655-9286-4e2e-a91d-b1c0f7cec851"
          transition="scale-transition"
          width="50"
        />
        <v-toolbar-title
          @click="goToHome()"
          style="color: black; cursor: pointer"
          >Crayondelune</v-toolbar-title
        >
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute bottom temporary>
      <v-list>
        <v-list-group :value="true" prepend-icon="mdi-palette" to="/art">
          <template v-slot:activator>
            <v-list-item-title>Art</v-list-item-title>
          </template>

          <v-list-item to="/flash">
            <v-list-item-content>
              <v-list-item-title>Flash</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-draw</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/portfolio">
            <v-list-item-content>
              <v-list-item-title>Portfolio</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-view-gallery </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            href="https://crayondelune.youcanbook.me"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon>mdi-calendar-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Reservation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/merch">
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Merch</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <transition name="slide" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <v-footer fixed padless app height="45" color="secondary">
      <v-col class="text-center accent--text">
        <span>&copy;Crayondelune {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
  }),

  methods: {
    goToHome() {
      this.$router.push("/");
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style>
.v-application {
  font-family: "Barlow", sans-serif;
}
.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s, transform 1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: transalateX(-30%);
}
</style>
